import React from "react"
import { Layout, LazyIframe } from "../components"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const videos = [
  {
    url: "https://www.youtube.com/embed/a4uooOGQRs4",
    title: "Blijven Spetteren! NL DOET!! Start up Burgerparticipatie!",
  },
  {
    url: "https://www.youtube.com/embed/Jmv_1tBCQYQ",
    title: "Protest tegen sluiting zwembad de Spetter",
  },
  {
    url: "https://www.youtube.com/embed/f1ZOPNtO4bk",
    title: "Blijven Spetteren! NL Doet! 2016 Magisto",
  },
  {
    url: "https://www.youtube.com/embed/YnFtLlnLqZQ",
    title: "Grote actie zwembad de Spetter",
  },
  {
    url: "https://www.youtube.com/embed/n8pc0vTZmkk",
    title: "Actie voor zwembad De Spetter te Tholen 2013 !!",
  },
]

const VideoGallery = () => {
  return (
    <Layout>
      <GatsbySeo
        title="Video Gallerie Blijven Spetteren"
        description="Video Gallerie Blijven Spetteren"
      />
      <div className="container prose prose-lg prose-slate mx-auto max-w-5xl">
        <h2>Video Gallerie</h2>
        <div className="grid lg:grid-cols-3">
          {videos.map((video, index) => (
            <div key={index} className="w-full p-4">
              <LazyIframe url={video.url} title={video.title} />
              <span>{video.title}</span>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default VideoGallery
